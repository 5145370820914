<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Equipementform from "./components/Equipementform.vue";

import { getDropDownElementsByTypeApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: { getDropDownElementsByTypeApi},
  page: {
    title: "Types des équipements",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Equipementform },
  data() {
    return {
      selectedEquipement: null,
      tableData: [],
      List_Equipement: [],
      rows:'',
      active:false,
      selectedRows:[],
      Equipement:undefined,
      title: "Types des équipements",
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Types des équipements",
          active: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchEquipementList();
  },
   watch:{
      selectedRows(){
      },
      Equipement:{
          handler(val){
            this.List_Equipement.push({
                name:val.name,
            });
            console.log(this.List_Equipement);
          }
          
          
      }
  },
  methods:{
    fetchEquipementList(){
      getDropDownElementsByTypeApi("equipment_types")
      .then(res => (this.data = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getEquipement(){
      getDropDownElementsByTypeApi("equipment_types")
      .then(response => {
        this.data = response.data.original.list;
          this.selectedRows.push(this.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshEquipement(){
      this.getEquipement();
    },
     deleteEquipement(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/equipment_types/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchEquipementList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editEquipement(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/infrastructure/equipment_types/getSingleEquipementData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var equipement = res.data.original.data.equipement;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedEquipement = equipement;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadEquipementlDataToFormComponent(selectedEquipement){
      if(selectedEquipement){
        this.editEquipement(selectedEquipement.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedEquipement.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedEquipement.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedEquipement.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedEquipement.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedEquipement.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(equipement){

      this.currentSelectedEquipement = equipement;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshEquipementTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des types </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_Equipement"
                    selectionMode="single"
                    selectedClass="table-info"
                     @selectionChanged="equipementTableRowSelected($event)"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody slot="body" >
                    <v-tr v-for="row in data" :key="row.id" :row="row">
                      <td>
                        {{ row.designation }}
                      </td>
                      <td>
                        
                        <a
                          @click="editEquipement(row.uuid)"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></a>

                        <a
                          @click="deleteEquipement(row.type, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> 
                    </v-tr>
                  </tbody>
                </v-table>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

            <Equipementform :base="selectedRows"  :equipement="currentSelectedEquipement"  />
      </div>
    </div>
  </Layout>
</template>